<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="form">
				<span>
					<el-form-item class="mb10" label="用户名">
						<el-input v-model.trim="form.username" placeholder="输入用户名" clearable></el-input>
					</el-form-item>
					<el-form-item class="mb10" label="姓名">
						<el-input v-model.trim="form.contact" placeholder="输入姓名" clearable></el-input>
					</el-form-item>
					<el-form-item class="mb10" label="手机号码">
						<el-input v-model.trim="form.mobile" placeholder="输入手机号码" clearable></el-input>
					</el-form-item>
					<el-form-item class="mb10" label="店铺状态">
						<el-select v-model="form.store_status" placeholder="请选择店铺状态" clearable>
							<el-option v-for="item in starts" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="推广人">
						<el-input v-model="form.name" placeholder="输入推广人"></el-input>
					</el-form-item>
					<el-form-item label="体彩编号">
						<el-input v-model="form.sport_lottery_seq" placeholder="输入体彩编号"></el-input>
					</el-form-item>
					<el-form-item label="福彩编号">
						<el-input v-model="form.welfare_lottery_seq" placeholder="输入福彩编号"></el-input>
					</el-form-item> -->
					<el-form-item label="开通时间">
						<el-date-picker v-model="time" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(1)" icon="el-icon-search">查询</el-button>
					</el-form-item>
				</span>
				<el-form-item>
					<el-button type="primary" @click="handleAdd" icon="el-icon-plus">创建店铺账号</el-button>
				</el-form-item>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="店铺ID" width="80">
			</el-table-column>
			<el-table-column prop="username" label="用户名" width="80">
			</el-table-column>
			<el-table-column prop="initpwd" label="初始密码" width="100">
			</el-table-column>
			<el-table-column prop="contact" label="姓名" width="100">
			</el-table-column>
			<!-- <el-table-column prop="role_name" label="推广人" width="100">
			</el-table-column> -->
			<el-table-column prop="create_at" label="创建时间" sortable width="160">
			</el-table-column>
			<el-table-column prop="sport_lottery_seq" label="体彩编号" width="140">
			</el-table-column>
			<el-table-column prop="welfare_lottery_seq" label="福彩编号" width="140">
			</el-table-column>
			<el-table-column prop="store_status" label="店铺状态" width="80" align="center">
			</el-table-column>
			<el-table-column prop="area_desc" width="180" label="地址">
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<template>
						<el-button type="primary" plain size="small" @click="handleEdit(scope.row.id)">查看资料</el-button>						
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>
	import { getStoreList, stateAdmin} from '../../api/api';
	export default {
		data() {
			return {
				time:[],
				form: {
					city_id: '',
					username: '',
					mobile: '',
					privince_id: '',
					my_host: '',
					store_status: '',
					start_time:  '',
					end_time: '',
					name: '',
				},
				starts:[
					{
						value:'全部',
						label: '0',
					},
					{
						value:'未生效',
						label: '1',
					},
					{
						value:'已生效',
						label: '2',
					},
					{
						value:'已过期',
						label: '3',
					}
				],

				listData: [], //管理员列表数据
				//分页
				total: 0,
				page: 1,
				pagesize: 10, //每页条数
				pagesizes: [10, 20, 50, 100], //可选条数
				listLoading: false,
				sels: [], //列表选中列
			}
		},
		watch:{
			'time': function(newValue,oldVale){
				if(this.time){
					this.form.start_time = this.time[0]
					this.form.end_time = this.time[1]
				}else{
					this.time = []
				}
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(type) {
                if(type) this.page = 1
				let para = this.form;
				para.page = this.page;
				para.pagesize = this.pagesize;

				this.listLoading = true;
				getStoreList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//恢复、冻结、删除
			handleState: function (index, row, text) {
				this.$confirm('确认'+text+'该用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					this.listLoading = true;
					var astatus = function(){
						return text == '冻结' ? 2 : text == '恢复' ? 1 : text == '批量冻结' ? 2 : text == '批量恢复' ? 1 : '0';
					}
					//判断是否批量
					var ids = this.sels.map(item => item.id).toString();
					var para = {
						id: ids,
						status: astatus()
					};
					stateAdmin(para).then((res) => {
						this.listLoading = false;
						if(res.code==1){
							this.$message({
								message: text+'成功',
								type: 'success'
							});
							this.getList();
						}else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
						}
					});
				}).catch(() => {

				});
			},
			//前往编辑界面
			handleEdit: function (id) {
				localStorage.setItem('accountListFrome', JSON.stringify(this.form))
				localStorage.setItem('accountListPage', this.page)
				this.$router.push({ name: '店铺详细信息', query: { id: id }})
			},
			//显示新增界面
			handleAdd: function () {
				this.$router.push({ name: '创建店铺账号'})
			},
		},
		created(){
			if(localStorage.getItem('accountListFrome')) this.form = JSON.parse(localStorage.getItem('accountListFrome'))
			if(localStorage.getItem('accountListPage')) this.page = parseInt(localStorage.getItem('accountListPage'))
			//获取列表数据
			this.getList();
		},
		mounted() {}
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/permission.scss";
</style>
